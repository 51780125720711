import { useSession } from 'src/sdk/session'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import type {
  ComprePorSelecaoPageQueryQuery,
  ComprePorSelecaoPageQueryQueryVariables,
} from '@generated/graphql'
// import { Image } from 'src/components/ui/Image'
import Newsletter from 'src/components/sections/Newsletter'
import { stringToSanitizedHtml } from 'src/utils/stringToSanitizedHtml'

const Page: FCC<
  PageProps<
    ComprePorSelecaoPageQueryQuery,
    ComprePorSelecaoPageQueryQueryVariables
  >
> = (props) => {
  const {
    data: { site },
    location: { host, pathname },
  } = props

  const { locale } = useSession()

  const title =
    'Compre por seleção: SexShop para Eles, para Elas, para Casais e Unisex'

  const canonical =
    host !== undefined
      ? `https://${host}${pathname.replace(/\/$/, '')}`
      : pathname.replace(/\/$/g, '')

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: '/compre-por-colecao',
            name: title,
            position: 1,
          },
        ]}
      />

      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

      {stringToSanitizedHtml(`
        <div className="hide-on-mobile" style="display: block;">
            <table border="0" align="center" cellpadding="0" cellspacing="0" style="width: 100%;">
                <tbody><tr>
                    <td><a href="/para-elas" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias_01.jpg" width="100%" alt="Itens de sexshop para mulheres" style="display:block;" border="0"></a>
                    </td>
                    <td><a href="/para-rles" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias_02.jpg" width="100%" alt="Itens de sexshop para homens" style="display:block;" border="0"></a>
                    </td>
                    <td><a href="/para-casais" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias_03.jpg" width="100%" alt="Itens de sexshop para casais" style="display:block;" border="0"></a>
                    </td>
                    <td><a href="/para-todos" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias_04.jpg" width="100%" alt="Itens de sexshop unissex" style="display:block;" border="0"></a>
                    </td>
                </tr>
                </tbody>
                </table>
                <table>
                        <tbody>
                            <tr>
                            <td><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias_05.jpg" width="100%" alt="" style="display:block;" border="0">
                            </td>
                            </tr>
                        </tbody>
                </table>

                <table>
                    <tbody>
                        <tr>
                            <td><a href="/sex-shop?sort=release_desc" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias_06.jpg" width="100%" alt="produtos de sex shop para mulheres" style="display:block;" border="0"></a>
                            </td>
                            <td><a href="/lingerie?sort=release_desc" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias_07.jpg" width="100%" alt="produtos de sex shop para homens" style="display:block;" border="0"></a>
                            </td>
                            <td><a href="/linha-noite?sort=release_desc" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias_08.jpg" width="100%" alt="produtos de sex shop para casais" style="display:block;" border="0"></a>
                            </td>
                            </tr>
                    </tbody>
                </table>
        </div>
      `)}
      {stringToSanitizedHtml(`
        <div class="hide-on-desktop" style="display: block;">
            <table border="0" align="center" cellpadding="0" cellspacing="0" style="width: 100%;">
                <tbody><tr>
                    <td><a href="/para-elas" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias-mobile_01.jpg" width="100%" alt="produtos de sexshop para elas" style="display:block;" border="0"></a>
                    </td>
                    <td><a href="/para-eles" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias-mobile_02.jpg" width="100%" alt="produtos de sexshop para eles" style="display:block;" border="0"></a>
                    </td>

                </tr>
                </tbody>
                </table border="0" align="center" cellpadding="0" cellspacing="0" style="width: 100%;">

                <table border="0" align="center" cellpadding="0" cellspacing="0" style="width: 100%;">
                        <tbody>



                    <tr><td><a href="/para-casais" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias-mobile_03.jpg" width="100%" alt="produtos de sexshop para casais" style="display:block;" border="0"></a>
                    </td>
                    <td><a href="/para-todos" border="0" style="display: flex;"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias-mobile_04.jpg" width="100%" alt="produtos de sexshop unissex style=" display:block;"="" border="0"></a>
                    </td>
                        </tr></tbody>
                </table>


                <table>
                        <tbody>
                            <tr>
                            <td><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias-mobile_05.jpg" width="100%" alt="" style="display:block;" border="0">
                            </td>
                            </tr>
                        </tbody>
                </table>

                <table>
                    <tbody>
                        <tr>
                            <td><a href="/sex-shop?sort=release_desc" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias-mobile_06.jpg" width="100%" alt="sex shop" style="display:block;" border="0"></a>
                            </td>
                            <td><a href="/lingerie?sort=release_desc" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias-mobile_07.jpg" width="100%" alt="lingerie" style="display:block;" border="0"></a>
                            </td>
                            <td><a href="/linha-noite?sort=release_desc" border="0"><img src="https://blog.miess.com.br/wp-content/uploads/2021/06/categorias-mobile_08.jpg" width="100%" alt="linha noite" style="display:block;" border="0"></a>
                            </td>
                            </tr>
                    </tbody>
                </table>
        </div>
      `)}

      <Newsletter />
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const query = graphql`
  query ComprePorSelecaoPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`
Page.displayName = 'Page'

export default mark(Page)
